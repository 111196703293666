'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.notifications
 # @description

###
angular
  .module 'mundoAdmin.notifications', [
    'ui.router'
  ]
  .run [
    'MundoNavigation'
    (MundoNavigation) ->
      MundoNavigation.addSection
        state: 'notifications'
        label: 'app.admin.notifications'
        icon: 'message'
        showExpression: '\
hasPermission("manage all MundoMosaNotificationBundle:Notification entities") ||\
hasPermission("manage tenant MundoMosaNotificationBundle:Notification entities")'
        items: [
            state: 'notifications.notifications'
            label: 'app.admin.pageTitles.notifications.notifications'
            icon: 'message'
            showExpression: '\
    hasPermission("manage all MundoMosaNotificationBundle:Notification entities") ||\
    hasPermission("manage tenant MundoMosaNotificationBundle:Notification entities")'
        ]
  ]
